import React from 'react'
import { Flex, Box } from 'reflexbox'
import { Container } from 'src/components/UI/Grid/Grid'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './404.module.scss'

const NotFoundPage = ({ pageContext }: any) => {
  return (
    <Container paddingTop={[19, null, null, 30]}>
      <Flex
        alignItems="center"
        justifyContent="center"
        marginBottom={[19, null, null, 12]}
        width={1}
      >
        <Box width={1}>
          <Flex flexDirection="column" justifyContent="center" as="h1">
            Oops! This is not the back stage area.
          </Flex>
          <LinkComponent to="/">
            Head back and look for another way
          </LinkComponent>
        </Box>
      </Flex>
    </Container>
  )
}

export default NotFoundPage
